<template>
  <div>
    <!-- Totalizer -->
    <FranchiseTotalizer :totalizer="totalizer" type="franchise" />
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="PN" label-for="searchConsultant">
              <v-select
                id="searchConsultant"
                multiple
                v-model="consultant"
                :reduce="(consultant_list) => consultant_list.id"
                :options="consultants"
                :loading="loading.consultants"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
                @input="setStructureOption"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Status" label-for="searchStatuses">
              <v-select
                id="searchStatuses"
                multiple
                v-model="status"
                :reduce="(status_list) => status_list.id"
                :options="statuses"
                :loading="loading.statuses"
                :close-on-select="false"
                label="label"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Período" label-for="searchNpsDate">
              <flat-pickr
                id="searchNpsDate"
                v-model="npsDate"
                class="form-control"
                :config="flatPickrConfig"
                placeholder="01 Jan 2020 até 31 Dez 2024"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Franqueado" label-for="searchTeam">
              <v-select
                id="searchFranchise"
                multiple
                v-model="franchise"
                :reduce="(franchise_list) => franchise_list.id"
                :options="franchises"
                :loading="loading.franchises"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
                @input="setStructureOption"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-1">
            <b-form-group
              label="Tipo de visualização"
              label-for="searchStructureOption"
            >
              <v-select
                id="searchStructureOption"
                v-model="structureOption"
                :reduce="(structure_list) => structure_list.key"
                :options="structures"
                :loading="loading.structures"
                label="name"
                @keyup.enter="search"
                :disabled="hasConsultantFranchiseSelected"
                :clearable="false"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              class="mr-1"
              :disabled="loading.table"
            >
              Limpar
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="search(1)"
              :disabled="loading.table"
            >
              <b-spinner v-if="loading.table" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.table ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="Não foram encontradas NPS para esta busca"
        :busy="loading.table"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.consultant_name }}</span>
          </div>
        </template>
        <!-- Column: franquia -->
        <template #cell(franchise_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.franchise_name }}</span>
          </div>
        </template>
        <!-- Column: % respostas -->
        <template #cell(percentage_responses)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.percentage_responses | toPercentage
            }}</span>
          </div>
        </template>
        <!-- Column: NPS -->
        <template #cell(nps_score)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.nps_score
            }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { getRangeDates } from "@/helpers/date_picker";
import useAppConfig from "@core/app-config/useAppConfig";
import FranchiseTotalizer from "../components/FranchiseTotalizer";
import { OWN, STRUCTURE } from "@/constants/structure_options"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BPopover,
    vSelect,
    flatPickr,
    FranchiseTotalizer,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        table: false,
        consultants: false,
        statuses: false,
        franchises: false,
        structures: false
      },
      tableColumns: [
        { key: "consultant_name", label: "PN", sortable: true },
        { key: "franchise_name", label: "Franquia", sortable: true },
        {
          key: "percentage_responses",
          label: "% de respostas",
          sortable: true,
        },
        { key: "nps_score", label: "NPS", sortable: true },
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      consultant: undefined,
      status: undefined,
      franchise: undefined,
      npsDate: undefined,
      structureOption: STRUCTURE
    };
  },
  computed: {
    ...mapGetters({
      npsSearchResult: types.NPS_STRUCTURE_SEARCH_RESULT,
      statuses: types.NPS_STATUSES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      consultants: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      totalizer: types.NPS_FRANCHISE_TOTALIZER,
      structures: sharedTypes.STRUCTURE_TYPES
    }),
    items: function () {
      return this.npsSearchResult?.data || [];
    },
    totalItems: function () {
      return this.npsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.npsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.npsSearchResult?.to || 0;
    },
    hasConsultantFranchiseSelected: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os franqueados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.statuses = true;
    this.getStatuses()
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.statuses = false;
      });
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os niveis para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.structures = true;
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
  },
  methods: {
    ...mapActions({
      searchNps: types.SEARCH_STRUCTURE_NPS,
      getStatuses: types.GET_NPS_STATUSES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getTotalizer: types.GET_NPS_FRANCHISE_TOTALIZER,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES
    }),
    search(currentPage = undefined) {
      this.loading.table = true;
      const { consultant, status, franchise, npsDate, order, itemsPerPage, structureOption } =
        this;
      const npsDates = getRangeDates(npsDate);
      this.searchNps({
        consultant,
        status,
        franchise,
        date_start: npsDates.start,
        date_end: npsDates.end,
        order,
        structure_option: structureOption,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
        limit: itemsPerPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os NPS. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });

      this.getTotalizer({
        consultant,
        status,
        franchise,
        structure_option: structureOption,
        date_start: npsDates.start,
        date_end: npsDates.end,
      }).catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar os dados do totalizador. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    setStructureOption() {
      if ((this.consultant && this.consultant.length > 0)
        || (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE
      }
    },
    clearSearch() {
      this.consultant = undefined;
      this.status = undefined;
      this.franchise = undefined;
      this.npsDate = undefined;
      this.order = undefined;
      this.sortField = null;
      this.search();
    },
    sort(event) {
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? "desc" : "asc";
        this.order = [event.sortBy, direction];
        this.sortField = event.sortBy;
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
